// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/truthist-styles.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$(),
  _s5 = $RefreshSig$(),
  _s6 = $RefreshSig$(),
  _s7 = $RefreshSig$(),
  _s8 = $RefreshSig$(),
  _s9 = $RefreshSig$(),
  _s10 = $RefreshSig$(),
  _s11 = $RefreshSig$(),
  _s12 = $RefreshSig$(),
  _s13 = $RefreshSig$(),
  _s14 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/truthist-styles.tsx");
  import.meta.hot.lastModified = "1733459677540.6008";
}
// REMIX HMR END

import { Heading, Text, useColorModeValue } from "@chakra-ui/react";
function TruthistH1({
  children,
  ...rest
}) {
  _s();
  return <Heading as="h1" size="4xl" textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Heading>;
}
_s(TruthistH1, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c = TruthistH1;
function TruthistH2({
  children,
  ...rest
}) {
  _s2();
  return <Heading as="h2" size="3xl" textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Heading>;
}
_s2(TruthistH2, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c2 = TruthistH2;
function TruthistH3({
  children,
  ...rest
}) {
  _s3();
  return <Heading as="h3" size="2xl" textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Heading>;
}
_s3(TruthistH3, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c3 = TruthistH3;
function TruthistH4({
  children,
  ...rest
}) {
  _s4();
  return <Heading as="h4" size="xl" textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Heading>;
}
_s4(TruthistH4, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c4 = TruthistH4;
function TruthistH5({
  children,
  ...rest
}) {
  _s5();
  return <Heading as="h5" size="lg" letterSpacing={"wide"} textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Heading>;
}
_s5(TruthistH5, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c5 = TruthistH5;
function TruthistH6({
  children,
  ...rest
}) {
  _s6();
  return <Heading as="h6" size="md" letterSpacing={"wide"} textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Heading>;
}
_s6(TruthistH6, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c6 = TruthistH6;
function TruthistH7({
  children,
  ...rest
}) {
  _s7();
  return <Heading as="h6" size="sm" letterSpacing={"wider"} textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Heading>;
}
_s7(TruthistH7, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c7 = TruthistH7;
function TruthistH8({
  children,
  ...rest
}) {
  _s8();
  return <Heading as="h6" size="xs" letterSpacing={"wider"} textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Heading>;
}
_s8(TruthistH8, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c8 = TruthistH8;
function TruthistB1({
  children,
  ...rest
}) {
  _s9();
  return <Text as="p" fontSize="2xl" textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Text>;
}
_s9(TruthistB1, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c9 = TruthistB1;
function TruthistB2({
  children,
  ...rest
}) {
  _s10();
  return <Text as="p" fontSize="xl" textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Text>;
}
_s10(TruthistB2, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c10 = TruthistB2;
function TruthistB3({
  children,
  ...rest
}) {
  _s11();
  return <Text as="p" fontSize="lg" letterSpacing={"wide"} textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Text>;
}
_s11(TruthistB3, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c11 = TruthistB3;
function TruthistB4({
  children,
  ...rest
}) {
  _s12();
  return <Text as="p" fontSize="md" letterSpacing={"wide"} textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Text>;
}
_s12(TruthistB4, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c12 = TruthistB4;
function TruthistB5({
  children,
  ...rest
}) {
  _s13();
  return <Text as="p" fontSize="sm" letterSpacing={"wider"} textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Text>;
}
_s13(TruthistB5, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c13 = TruthistB5;
function TruthistB6({
  children,
  ...rest
}) {
  _s14();
  return <Text as="p" fontSize="xs" letterSpacing={"wider"} textColor={useColorModeValue("black", "white")} {...rest}>
      {children}
    </Text>;
}
_s14(TruthistB6, "3skuTHwppfEdh6aKNlffDjyTB8U=", false, function () {
  return [useColorModeValue];
});
_c14 = TruthistB6;
export { TruthistB1, TruthistB2, TruthistB3, TruthistB4, TruthistB5, TruthistB6, TruthistH1, TruthistH2, TruthistH3, TruthistH4, TruthistH5, TruthistH6, TruthistH7, TruthistH8 };
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10, _c11, _c12, _c13, _c14;
$RefreshReg$(_c, "TruthistH1");
$RefreshReg$(_c2, "TruthistH2");
$RefreshReg$(_c3, "TruthistH3");
$RefreshReg$(_c4, "TruthistH4");
$RefreshReg$(_c5, "TruthistH5");
$RefreshReg$(_c6, "TruthistH6");
$RefreshReg$(_c7, "TruthistH7");
$RefreshReg$(_c8, "TruthistH8");
$RefreshReg$(_c9, "TruthistB1");
$RefreshReg$(_c10, "TruthistB2");
$RefreshReg$(_c11, "TruthistB3");
$RefreshReg$(_c12, "TruthistB4");
$RefreshReg$(_c13, "TruthistB5");
$RefreshReg$(_c14, "TruthistB6");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;